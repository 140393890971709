import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import ProductGrid from '../components/productGrid'
import Template from '../components/template'
import useData, { Routes } from '../hooks/useData'
import { Helmet } from 'react-helmet'
import categories from '../helper/categories'

const Home: FunctionComponent = () => {
    const [query, setQuery] = useState()
    const data = useData(Routes.PRODUCTS)
    const [filtered, setFiltered] = useState(data)
    const [price, setPrice] = useState<[number, number]>()
    const [categoryFilter, setCategoryFilter] = useState<string[]>([])
    const [searchFilter, setSearchFilter] = useState<string>()

    useEffect(() => {
        setFiltered(data)
    }, [data])

    const handleSearchFilter = (search: string) => {
        setSearchFilter(search)
    }

    const handleFilter = (categories: string[]) => {
        setCategoryFilter(categories)
    }

    const productCategories = useMemo(() => categories(data ?? []), [data])

    const minMax = useMemo(() => {
        return data?.reduce<[number, number]>((prev, next) => {
            const [min, max] = prev

            return [Math.min(min, next.price), Math.max(max, next.price)]
        }, [Infinity, -Infinity])
    }, [data])

    useEffect(() => {
        const min = Math.min(...(minMax || []), ...(price || []))
        const max = Math.max(...(minMax || []), ...(price || []))

        setPrice([min, max])
    }, [minMax])

    const onPrice = (price: [number, number]) => {
        setPrice(price)
    }

    useEffect(() => {
        const filteredData = data?.filter(row => {
            const [min, max] = price || [null, null]

            const priceCheck = min && max ? row.price >= min && row.price <= max : true
            const searchCheck = searchFilter ? row.name.includes(searchFilter) : true
            const categoryCheck = categoryFilter.length > 0 ? categoryFilter.includes(row.category) : true

            return priceCheck && searchCheck && categoryCheck
        })

        setFiltered(filteredData)
    }, [price, categoryFilter, searchFilter])

    return (
        <Template filterProps={{ onSearch: handleSearchFilter, onPrice, onFilter: handleFilter, minMax, categories: productCategories }} filter>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Antiques & stuff</title>
            </Helmet>
            {
                data &&
                <ProductGrid products={filtered || []} />
            }
        </Template>
    )
}

export default Home