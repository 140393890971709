import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search';
import { colors } from '../style-defaults';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const Navbar: FunctionComponent = () => {
    return (
        <>
            <Container>
                <Link to='/'>
                    <h1>Antiques & Stuff</h1>
                </Link>
            </Container>
        </>
    )
}

const Container = styled.div`
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${colors.border};

    a {
        text-decoration: none;
    }
`

const Icon = styled(SearchIcon)`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 2em;
    cursor: pointer;
    font-size: clamp(1.25em, 3.5vw, 2.125em);
    fill: ${colors.body};
`


export default Navbar