import { useEffect, useState } from 'react'
import { origin } from '../App'
import { ProductType } from '../types/product'

export enum Routes {
    PRODUCTS = '/products',
    USERS = '/users',
    CONTACT = '/contact',
    FAQ = '/faq',
    ABOUT = '/about'
}

interface QueryData {
    id?: string,
    category?: string,
    price?: string
}

export const useTextData = (url: Routes) => {
    const [data, setData] = useState<any>()
    const newurl = new URL(url, origin)

    useEffect(() => {
        fetch(newurl.toString()).then((response) => {
            response.json().then(
                (res) => {
                    setData(res)
                }
            )
        })
    }, [url])


    return data
}

const useData = (url: Routes, queryData?: QueryData) => {
    const [data, setData] = useState<ProductType[]>()
    const query = Object.entries(queryData || []).map(([key, value]) => `${key}=${value}`).join('&')

    const newurl = new URL(url + '?' + query, origin)

    useEffect(() => {
        fetch(newurl.toString()).then((response) => {
            response.json().then(
                (res) => {
                    setData(res)
                }
            )
        })
    }, [url, queryData])


    return data
}

export const useDataWithResponse = (url: Routes, id?: string) => {
    const [data, setData] = useState<{ response?: Response, data?: ProductType }>({})
    const newurl = new URL(url + '/' + id, origin)

    useEffect(() => {
        fetch(newurl.toString()).then((response) => {
            if (response.status === 404) {
                return setData({
                    response
                })
            }

            response.json().then(
                (res) => {
                    setData({
                        response,
                        data: res
                    })
                }
            )
        })
    }, [])

    return data
}

export default useData