import React, { FunctionComponent, useEffect, useState } from "react";
import Template from '../components/template'
import { useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet'
import { Routes, useDataWithResponse } from "../hooks/useData";
import { useNavigate } from 'react-router';
import ProductDesktop from "../components/productDesktop";
import styled from 'styled-components'
import useMobile from "../hooks/isMobile";
import ProductMobile from "../components/productMobile";

const Product: FunctionComponent = () => {
    const id = new URLSearchParams(useLocation().search).get('id') as string
    const { data, response } = useDataWithResponse(Routes.PRODUCTS, id)
    const isMobile = useMobile()

    const history = useNavigate()
    const mobile = useMobile()

    useEffect(() => {
        if (response?.status === 404) {
            history('/')
        }
    }, [response])


    const View = mobile ? ProductMobile : ProductDesktop

    return (
        <Template>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{data ? data.name + ' | ' : ''}  Antiques & stuff</title>
            </Helmet>
            <ProductContainer isMobile={isMobile}>
                {data !== undefined &&

                    <View data={data} />
                }
            </ProductContainer>

        </Template>
    )
}

type ProductContianerType = {
    isMobile: boolean
}

const ProductContainer = styled.div<ProductContianerType>`
    margin: ${props => props.isMobile ? '40px 60px 80px 60px' : '100px 200px 100px 200px'};
`

export default Product