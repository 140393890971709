import { FunctionComponent } from 'react';

type IconProps = {
  size: number
}

const Facebook: FunctionComponent<IconProps> = ({ size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 0H16C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0ZM15 2H12.5C11.5717 2 10.6815 2.36875 10.0251 3.02513C9.36875 3.6815 9 4.57174 9 5.5V8H7V11H9V18H12V11H15V8H12V6C12 5.73478 12.1054 5.48043 12.2929 5.29289C12.4804 5.10536 12.7348 5 13 5H15V2Z" fill="#68513B" />
    </svg>
  );
};

export default Facebook;
