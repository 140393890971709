import { ProductType } from "../types/product"

const categories = (data: ProductType[]) => {
    const allCategories = data.map(product => product.category)

    const uniqueCategories = new Set(allCategories)

    return Array.from(uniqueCategories)
}

export default categories