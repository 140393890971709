import { useCallback, useState } from "react"

const useDebounce = (fn: any, timeout: number) => {
    const [, setTimeoutId] = useState<NodeJS.Timeout>()

    const restart = useCallback((...args) => {
        const id = setTimeout(() => fn(...args), timeout)

        setTimeoutId((current) => {
            current && clearTimeout(current)
            return id
        })
    }, [timeout, fn])

    return restart
}

export default useDebounce