import { FunctionComponent } from "react";
import Template from '../components/template'
import { Routes, useTextData } from "../hooks/useData";
import { AboutType } from '../types/text'
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components'
import useMobile from "../hooks/isMobile";

const About: FunctionComponent = () => {
    const about: AboutType = useTextData(Routes.ABOUT) ?? { text: '' }
    const isMobile = useMobile()

    return (
        <Template>
            <Container isMobile={isMobile}>
                <h1>Om oss</h1>
                <ReactMarkdown children={about.text} />
            </Container>
        </Template>
    )
}

type ContianerType = {
    isMobile: boolean
}

const Container = styled.div<ContianerType>`
    margin: ${props => props.isMobile ? '40px 60px 80px 60px' : '100px 200px 100px 200px'};
`

export default About