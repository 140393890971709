import { origin } from "../App";
import { ProductType } from "../types/product";


export const oneImg = (product: ProductType) => {
    return origin + product.pictures[0].url
}

export const images = (product: ProductType) => {
    const imgSorces = product.pictures.map(img => origin + img.url)

    return imgSorces
}