import styled from 'styled-components'
import { Link } from "react-router-dom";
import { FunctionComponent } from 'react'
import useData, { Routes } from '../hooks/useData'
import { ProductType } from '../types/product';
import { oneImg } from '../helper/img';

interface Product {
    product: ProductType
}


const Product: FunctionComponent<Product> = ({ product }) => {
    const imageSorce = oneImg(product)

    return (
        <ProductContainer>
            <Link to={`/produkter?id=${product.id}`}>
                <img
                    src={imageSorce}
                    alt={product.name + ' image'}
                    height="440px"
                    width="300px"
                />
                <div>
                    <p>{product.name}</p>
                    <p>{product.price} kr</p>
                </div>
            </Link>
        </ProductContainer>

    )
}

const ProductContainer = styled.div`
    text-align: center;
    padding-bottom: 2em;
    cursor: pointer;
    
    a {
        text-decoration: none;
    }

    div {
        padding-top: 2.5em;
    }

    img {
        transition: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
        object-fit: cover;
    }

    img:hover { 
        filter: grayscale(50%);
    }
`

export default Product