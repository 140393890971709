import Footer from './footer'
import Navbar from './navbar'
import Filter, { FilterType } from './filter';
import { FunctionComponent } from 'react';
import styled from 'styled-components'


const Template: FunctionComponent<{ filter?: boolean, filterProps?: FilterType }> = ({ filter, children, filterProps }) => {
    return (
        <>
            <Navbar />
            {filter && <Filter {...filterProps} />}
            <Contaier>
                {children}
            </Contaier>
            <Footer />
        </>
    )
}

const Contaier = styled.div`
    min-height: 50vh;
`

export default Template