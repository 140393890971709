import { FunctionComponent } from "react";
import Template from '../components/template'
import { Routes, useTextData } from "../hooks/useData";
import { FAQType } from '../types/text'
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components'
import useMobile from "../hooks/isMobile";

const FAQ: FunctionComponent = () => {
    const faq: FAQType = useTextData(Routes.FAQ) ?? { text: '' }
    const isMobile = useMobile()

    console.log(faq)
    return (
        <Template>
            <Container isMobile={isMobile}>
                <h1>FAQ</h1>
                <ReactMarkdown children={faq.text} />
            </Container>
        </Template>
    )
}

type ContianerType = {
    isMobile: boolean
}

const Container = styled.div<ContianerType>`
    margin: ${props => props.isMobile ? '40px 50px 80px 50px' : '100px 150px 100px 150px'};
`

export default FAQ