import { FunctionComponent } from "react"
import styled from 'styled-components'
import { images } from "../helper/img"
import { colors } from "../style-defaults"
import { ProductType } from "../types/product"
import ContactButton from "./contactButton"
import ImageSelectorMobile from './imageSelectorMobile'
type Data = {
    data: ProductType
}

const ProductMobile: FunctionComponent<Data> = ({ data }) => {
    const imgSources = images(data)
    return (
        <Container>
            <ImageSelectorMobile sources={imgSources} productName={data.name} />
            <InformationContainer>
                <NamePriceContainer>
                    <div>
                        <h1>{data.name}</h1>
                        <p>{data.price} kr</p>
                    </div>
                </NamePriceContainer>
                <DescriptionContainter>
                    <p>{data.description}</p>
                    <ContactButton />
                </DescriptionContainter>

            </InformationContainer>
        </Container>

    )
}

const Container = styled.div`
`

const InformationContainer = styled.div`

`

const NamePriceContainer = styled.div`
    border-bottom: 2px solid ${colors.border};
    width: 100%;
    overflow-wrap: break-word;
    height: fit-content;
`

const DescriptionContainter = styled.div`
`

export default ProductMobile