import React from 'react';
import logo from './logo.svg';
import './App.css';
import { createGlobalStyle } from 'styled-components'
import { colors, fonts } from './style-defaults'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './views/home';
import Product from './views/product';
import FAQ from './views/faq';
import About from './views/about';

export const origin = 'https://strapi.antiquesandstuff.se'

function App() {
  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path='/produkter' element={<Product />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/om' element={<About />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.bg};
    overflow-y: scroll;
  }

  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    max-width: 100%;
    overflow-x: hidden;
  }

  h1,
  h2 {
    font-family: ${fonts.titles};
    color: ${colors.titles};
    font-weight: normal;
    font-size: clamp(1.5em, 3.5vw, 2em);
  }

  p, span {
    font-family: ${fonts.body};
    color: ${colors.body};
  }

  p {
    font-size: 1.125em;
  }

`