import styled from 'styled-components'
import { colors } from '../style-defaults';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { Fade, Input, InputAdornment, InputLabel, Paper, Popper, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import useDebounce from '../hooks/useDebounce';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export type FilterType = {
    onSearch?: (search: string) => void,
    onFilter?: (categories: string[]) => void,
    onPrice?: (range: [number, number]) => void,
    minMax?: [number, number],
    categories?: string[]
}

type Marks = {
    value: number,
    label: string,
}

const Filter: FunctionComponent<FilterType> = ({ minMax, onSearch, onPrice, onFilter, categories }) => {
    const searchDebounce = useDebounce(onSearch, 500)
    const priceDebounce = useDebounce(onPrice, 50)
    const [value, setValue] = useState(minMax);
    const [marks, setMarks] = useState<Marks[]>()
    const [filterCategories, setFilterCategories] = useState<string[]>([])
    const [showPriceSlider, setShowPriceSlider] = useState(true)

    useEffect(() => {
        // If any of the max/min are not finite, dont show slider
        setShowPriceSlider(minMax?.every(v => Number.isFinite(v)) ?? true)
    }, [minMax])

    useEffect(() => {
        priceDebounce(value)
    }, [value])

    useEffect(() => {
        setValue(minMax)
        if (minMax && minMax?.length > 0) {
            setMarks([
                {
                    value: minMax[0],
                    label: minMax[0].toString()
                },
                {
                    value: minMax[1],
                    label: minMax[1].toString()
                }
            ])
        }
    }, [minMax])

    const selectHandler = (target: string) => {
        const checked = filterCategories?.includes(target)

        if (checked) {
            setFilterCategories(filterCategories?.filter(category => category !== target))
        } else {
            setFilterCategories([...filterCategories, target])
        }
    }

    useEffect(() => {
        onFilter?.(filterCategories ?? [])
    }, [filterCategories])

    return (
        <FilterBar>
            <FilterOption baseline>
                <Input

                    placeholder="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(input) => {
                        searchDebounce(input.target.value)
                    }}
                />
            </FilterOption>
            <FilterOption>
                <FilterPopper>
                    <FilterField>
                        {
                            showPriceSlider && (
                                <>
                                    <p>Pris</p>
                                    <Box sx={{ width: '100%' }}>
                                        {minMax &&
                                            <Slider
                                                value={value}
                                                onChange={(event, newValue) => setValue(newValue as any)}
                                                valueLabelDisplay="auto"
                                                marks={marks}
                                                min={minMax[0]}
                                                max={minMax[1]}
                                            />}
                                    </Box>
                                </>

                            )

                        }
                        <p>Kategorier</p>
                        <FormGroup >
                            {
                                categories?.map(category => {
                                    return <FormControlLabel key={category} control={<Checkbox name={category} onChange={(event) => selectHandler(event.target.name)} />} label={category} />
                                })
                            }
                        </FormGroup>
                    </FilterField>
                </FilterPopper>
            </FilterOption>
        </FilterBar>
    )
}

const FilterPopper: React.FC = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const anchor = useRef(null)

    return (
        <FilterContainer ref={anchor}>
            {isOpen && <BG onClick={() => setIsOpen(!isOpen)} />}
            <div onClick={() => setIsOpen(!isOpen)}>
                <span>Filter</span>
                <IconRotator flip={isOpen}>
                    <KeyboardArrowDownIcon />
                </IconRotator>
            </div>
            <Popper style={{ width: '20%', minWidth: '200px' }} open={isOpen} anchorEl={anchor.current} placement={"bottom"} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={300}>
                        <Paper>
                            {children}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </FilterContainer>
    )
}

const FilterField = styled.div`
    padding: 15%;

    span {
        color: ${colors.footerText};
        box-shadow: unset !important;   
    }

    .MuiSlider-valueLabel {
        background-color: #68513B !important;

        span {
            color: white !important;
        }
    }
`

const BG = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: grab;
`

const IconRotator = styled.div<{ flip?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateX(${({ flip }) => flip ? '180deg' : '0deg'});
    transition: transform 300ms ease;
    transform-origin: center;
`

const FilterBar = styled.div`
    height: 3.438em;
    width: 100%;
    border-bottom: 1px solid ${colors.border};
    display: flex;
    justify-content: space-between;
`

const FilterContainer = styled.div`
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &> div {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: ${colors.titles};
        }
    }
`

const FilterOption = styled.div<{ baseline?: boolean }>`
    display: flex;
    width: 20%;
    border-left: ${props => props.baseline ? 'none' : `1px solid ${colors.border}`};
    align-items: ${props => props.baseline ? 'flex-end' : 'center'};
    justify-content: center;
    margin: ${props => props.baseline && '5px 0 10px 45px'};
`

export default Filter