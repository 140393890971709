import { FunctionComponent } from 'react';
import styled from 'styled-components'
import { colors } from '../style-defaults';
import Facebook from './facebook';
import Instagram from './instagram';
import { Routes, useTextData } from "../hooks/useData";
import { Contact } from '../types/text'
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';


const Footer: FunctionComponent = () => {
    const contact: Contact = useTextData(Routes.CONTACT) ?? { text: '' }

    return (
        <Container>
            <FooterWrapper>
                <Column>
                    <Title>Antiques & Stuff</Title>
                    <Paragraph>Kontakta Oss:</Paragraph>
                    <ReactMarkdown children={contact.text} />
                </Column>
                <Row style={{ alignItems: "flex-end" }}>
                    <IconContainer>
                        <Facebook size={30} />
                    </IconContainer>
                    <IconContainer>
                        <Instagram size={30} />
                    </IconContainer>
                </Row>
                <Column>
                    <Link to='/om'>
                        <Paragraph>Om oss</Paragraph>
                    </Link>
                    <Link to='/faq'>
                        <Paragraph>FAQ</Paragraph>
                    </Link>
                </Column>
            </FooterWrapper>
        </Container>
    )
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 4em;

    p {
        margin: 0.25em 0;
        font-size: clamp(0.75em, 3.5vw, 1em);
        color: ${colors.footerText};
        font-weight: 300;
    }

    a {
        text-decoration: unset;
    }
`

const Row = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

const IconContainer = styled.div`
    margin: 0 2em;
`

const Title = styled.h1`
    font-size: clamp(0.875em, 3.5vw, 1.5em);
    color: ${colors.footerText};
`

const Paragraph = styled.p`
    margin: 0.25em 0;
    font-size: clamp(0.75em, 3.5vw, 1em);
    color: ${colors.footerText};
    font-weight: 300;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    padding: 1.5em 2em 6em 1.5em;
    margin-top: 1em;
    background-color: ${colors.footer};
    justify-content: center;
`

const FooterWrapper = styled.div`
    width: 40em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`


export default Footer