const colors = {
    bg: '#F9F9F9',
    titles: '#636363',
    body: '#8A8A8A',
    border: '#C4C4C4',
    footer: '#ECE5DB',
    footerText: '#68513B'
}

const fonts = {
    titles: 'Playfair Display',
    body: 'Montserrat'
}

export { colors, fonts }