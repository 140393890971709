import { FunctionComponent, useState } from "react";
import styled from 'styled-components'
import { colors } from "../style-defaults";


type ImageSelectorType = {
    sources: string[]
    productName: string
}

const ImageSelector: FunctionComponent<ImageSelectorType> = ({ sources, productName }) => {
    const [selected, setSelected] = useState<number>(0)

    return (
        <Containter>
            <ImageSelectorContainer>
                {
                    sources.map((source, index) => {
                        return (
                            <ImgContainer
                                Border={index === selected}
                                key={index}
                            >
                                <img
                                    src={source}
                                    alt={productName + ' image' + index}
                                    onClick={() => setSelected(index)}
                                />
                            </ImgContainer>
                        )
                    })
                }
            </ImageSelectorContainer>
            <img
                src={sources[selected]}
                alt={productName + ' image' + selected}
                className="display"
            />
        </ Containter>
    )
}
const Containter = styled.div`
    display: grid;
    column-gap: 10%;
    grid-template-columns: 1fr 3fr;

    .display {
        width: 100%;
        height: 100%; 
        max-height: 650px; 
        max-width: 460px;
        min-height: 430px; 
        min-width: 280px;
        object-fit: cover;
    }
`

type ImgContainerProps = {
    Border: boolean
}

const ImgContainer = styled.div<ImgContainerProps>`
    transition: border 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    border: ${props => (props.Border ? 'solid 1px black' : 'solid 1px ' + colors.bg)};
    padding: 5px;
    width: fit-content;
`

const ImageSelectorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 30px;

    img {
        transition: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
        max-width: 90px;
        max-height: 140px;
        min-width: 50px;
        min-height: 110px;
        object-fit: cover;
        vertical-align: middle;
    }

    img:hover { 
        filter: grayscale(50%);
    }
`

export default ImageSelector