import styled from 'styled-components'
import { colors } from '../style-defaults'
import { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material'
import useMobile from '../hooks/isMobile'
import { Contact } from '../types/text'
import { Routes, useTextData } from '../hooks/useData'
import ReactMarkdown from 'react-markdown';

const ContactButton = () => {
    const [active, setActive] = useState<boolean>(false)
    const isMobile = useMobile()
    const contact: Contact = useTextData(Routes.CONTACT) ?? { text: '' }

    useEffect(() => {
        document.body.style.overflow = active ? 'hidden' : 'unset'

        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [active])

    return (
        <>
            <Container onClick={() => setActive(true)}>
                <p>Intresserad? Kontakta oss!</p>
            </Container>
            {active &&
                <>
                    <Popup onClick={() => setActive(false)} />
                    <PopupContainer isMobile={isMobile}>
                        <h1>Hittat något? Kontakta oss här!</h1>
                        <ReactMarkdown children={contact.text} />
                        <Close onClick={() => setActive(false)} />
                    </PopupContainer>
                </>
            }
        </>
    )
}

const Container = styled.div`
    background-color: ${colors.footer};
    width: 100%;
    padding: 3% 15%;
    margin: 40px auto;
    cursor: pointer;
    
    p {
        color: ${colors.footerText};
        margin: unset;
        text-align: center;
    }
`
const Popup = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #00000040;
    cursor: pointer;
    z-index: 0;
`
type PopupContainerType = {
    isMobile: boolean
}

const PopupContainer = styled.div<PopupContainerType>`
    background-color: ${colors.footer};
    width: ${props => props.isMobile ? '80%' : '40%'};
    height: ${props => props.isMobile ? '60%' : '50%'};
    top: ${props => props.isMobile ? '15%' : '20%'};
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    padding: ${props => props.isMobile ? '30px 40px' : '30px 80px'};
    z-index: 100;

    h1, p {
        color: ${colors.footerText};
    }

    svg {
        fill: ${colors.footerText};
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }
`

export default ContactButton