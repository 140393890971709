import { FunctionComponent, useState } from "react";
import styled from 'styled-components'
import { colors } from "../style-defaults";


type ImageSelectorType = {
    sources: string[]
    productName: string
}

const ImageSelectorMobile: FunctionComponent<ImageSelectorType> = ({ sources, productName }) => {
    const [selected, setSelected] = useState<number>(0)

    return (
        <>
            <Containter>
                <img
                    src={sources[selected]}
                    alt={productName + ' image' + selected}
                    className="display"
                />
                <ImageSelectorContainer>
                    {
                        sources.map((source, index) => {
                            return (
                                <ImgContainer
                                    Border={index === selected}
                                    key={index}
                                >
                                    <img
                                        src={source}
                                        alt={productName + ' image' + index}
                                        onClick={() => setSelected(index)}
                                    />
                                </ImgContainer>
                            )
                        })
                    }
                </ImageSelectorContainer>
            </ Containter>
        </>
    )
}

const Containter = styled.div`
    text-align: center;

    .display { 
        width: 60%;
        height: 50vh;
        min-width: clamp(200px, 2vw, 300px);
        object-fit: cover;
    }
`

type ImgContainerProps = {
    Border: boolean
}

const ImgContainer = styled.div<ImgContainerProps>`
    transition: border 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    border: ${props => (props.Border ? 'solid 1px black' : 'solid 1px ' + colors.bg)};
    padding: 5px;
    width: fit-content;
`

const ImageSelectorContainer = styled.div`
    display: flex;
    align-self: center;
    column-gap: 30px;
    margin-top: 30px;
    place-content: center;

    img {
        transition: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
        width: 10vw;
        height: 12vw;
        object-fit: cover
    }

    img:hover { 
        filter: grayscale(50%);
    }
`

export default ImageSelectorMobile